import type { Partner } from "@fscrypto/domain/partner";
import { cn } from "~/utils/cn";

const CLOUDINARY_BASE_URL = "https://res.cloudinary.com/dsr37ut2z/image/upload/v1722893427/assets/project-icons";

interface Props {
  slug: string;
  className?: string;
}

export const PartnerIcon = ({ slug, className }: Props) => {
  return (
    <img
      src={`${CLOUDINARY_BASE_URL}/${slug}.png?1`}
      alt={`${slug} icon`}
      className={cn("ml-1 mr-2 size-6", className)}
    />
  );
};
